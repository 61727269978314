import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// import Map from "./map";
// import Contact from "./Contact";

import Contact from "./Contact";

export default function Footer() {
  return (
    <>
      <section className="section" style={{ backgroundColor: "#54b6c9" }}>
        <div className="columns is-centered klaviyo-form has-text-centered">
          <div className="column is-5">
            <h3 className="title is-3">GET DISCOUNTS EVERY WEEK</h3>
            <div className="klaviyo-form-UcQL9s"></div>
            {/* <Contact /> */}
          </div>
        </div>
      </section>
      <footer className="footer is-clipped">
        <div className="content">
          <div className="columns">
            <div className="column f-logo">
              <div className="f-logo-in">
                <StaticImage
                  src="../img/bay-smokes/bay-smokes-logo-new.png"
                  alt="Logo"
                />
              </div>
              <div className="social-icons">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/baysmokes/"
                    >
                      <StaticImage
                        src="../img/bay-smokes/fb-ic.svg"
                        alt="Facebook"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@baysmokes"
                    >
                      <StaticImage
                        src="../img/bay-smokes/yt-icon.svg"
                        alt="Youtube"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/bay.smokes/?hl=en"
                    >
                      <StaticImage
                        src="../img/bay-smokes/insta-ic.svg"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://x.com/baysmokes?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                    >
                      <StaticImage
                        src="../img/bay-smokes/tw-icon.svg"
                        alt="X"
                      />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.tiktok.com/@baysmokes">
                      <StaticImage
                        src="../img/bay-smokes/tt-icon.svg"
                        alt="Tiktok"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column">
              {/* <p
                className="is-size-6 has-text-weight-bold"
                style={{ color: "#77B0C9" }}
              >
                SHOP DELTA 8
              </p>
              <Link to="/collections/delta-8-products/delta8-gummies">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 GUMMIES
                </p>
              </Link>

              <Link to="/collections/delta-8-products/delta8-flower">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 FLOWER
                </p>
              </Link>

              <Link to="/collections/delta-8-products/delta8-carts">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 CARTS
                </p>
              </Link>

              <Link to="/collections/delta-8-products/delta8-disposables">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 DISPOSABLES
                </p>
              </Link>

              <Link to="/collections/delta-8-products/delta-8-edibles/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA 8 EDIBLES
                </p>
              </Link>

              <Link to="/collections/delta-8-products/delta-8-prerolls">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 PREROLLS
                </p>
              </Link>

              <Link to="/collections/delta-8-dabs-and-wax">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 DABS & WAX
                </p>
              </Link> */}

              <p className="title is-6" style={{ color: "#87c6b9" }}>
                SHOP DELTA 9
              </p>
              <Link
                to="/collections/delta9-thc/"
                className="title is-7 has-text-white"
              >
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA 9 GUMMIES
                </p>
              </Link>

              <p className="title is-6 mt-5" style={{ color: "#4e73cf" }}>
                SHOP THCV
              </p>
              <Link to="/collections/thcv">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCV CARTS
                </p>
              </Link>

              <p className="title is-6" style={{ color: "#8547cb" }}>
                SHOP THCP
              </p>
              <Link to="/collections/thcp-products-for-sale/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCP CARTS
                </p>
              </Link>
            </div>

            <div className="column">
              {/* <p
                className="is-size-6 has-text-weight-bold"
                style={{ color: "#98d079" }}
              >
                SHOP CBD
              </p>
              <Link to="/collections/cbd-flower">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  CBD FLOWER
                </p>
              </Link> */}

              <p className="title is-6" style={{ color: "#b34ccb" }}>
                SHOP THCA
              </p>
              <Link to="/collections/thca-flower">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA FLOWER
                </p>
              </Link>
              <Link to="/collections/thca-diamonds">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA DIAMONDS
                </p>
              </Link>
              <Link to="/collections/thca-concentrates/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA Concentrates
                </p>
              </Link>
              <Link to="/collections/thca-vape-carts-disposables/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA CARTS & VAPE
                </p>
              </Link>
              <Link to="/collections/thca-gummies/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA GUMMIES
                </p>
              </Link>
              <Link to="/collections/thca-disposable-vapes/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA DISPOSABLE VAPES
                </p>
              </Link>
              <Link to="/pages/thca-wholesale">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  WHOLESALE THCA FLOWER
                </p>
              </Link>
              <p className="title is-6 mt-5" style={{ color: "#98d079" }}>
                SHOP KRATOM
              </p>
              <Link to="/collections/kratom">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  KRATOM
                </p>
              </Link>
            </div>

            <div className="column">
              <p
                className="is-size-6 has-text-weight-bold"
                style={{ color: "#afd372" }}
              >
                TOP SELLERS
              </p>
              <Link to="/collections/thca-flower">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCA FLOWER
                </p>
              </Link>
              <Link to="/collections/delta8-carts">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 CARTS
                </p>
              </Link>
              <Link to="/collections/thcp-products-for-sale/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCP CARTS
                </p>
              </Link>
              <Link to="/collections/delta9-thc/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA9 GUMMIES
                </p>
              </Link>
              <Link to="/collections/delta8-gummies">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA8 GUMMIES
                </p>
              </Link>
              <Link to="/collections/thcv">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  THCV VAPES
                </p>
              </Link>
            </div>

            <div className="column">
              <p
                className="is-size-6 has-text-weight-bold"
                style={{ color: "#b6c286" }}
              >
                HELPFUL LINKS​
              </p>
              <Link to="/account">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  SUBSCRIPTION PORTAL
                </p>
              </Link>
              <Link to="/affiliate-signup">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  BECOME AN AFFILIATE
                </p>
              </Link>

              <a href="https://wholesale.baysmokes.com/pages/wholesale-registration-form">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  WHOLESALE INQUIRY
                </p>
              </a>
              <Link to="/privacy-policy">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  PRIVACY POLICY
                </p>
              </Link>
              <Link to="/refund-policy">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  REFUND POLICY
                </p>
              </Link>
              <Link to="/shipping-policy">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  SHIPPING POLICY
                </p>
              </Link>
              <Link to="/terms-of-service">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  TERMS OF SERVICE
                </p>
              </Link>
              <Link to="/pages/who-we-are">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  WHO WE ARE
                </p>
              </Link>
              <Link to="/blogs/">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  DELTA-8 BLOG
                </p>
              </Link>
              <Link to="/sitemap.xml">
                <p className="is-size-6 has-text-weight-bold has-text-white mb-3">
                  SITEMAP
                </p>
              </Link>
            </div>

            <div className="column">
              <p
                className="is-size-6 has-text-weight-bold"
                style={{ color: "#beb54f" }}
              >
                CONTACT US
              </p>
              <p className="has-text-white is-size-6">
                Email us for questions or help! We're active from Sun-Fri and
                usually respond same day or within 24 hours. Our team loves to
                help you out because we're all family, please remember to have
                patience and show love :)
              </p>
              <p>
                <a className="has-text-white" href="mailto:hey@baysmokes.com">
                  hey@baysmokes.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <section
        className="section p-6 has-text-centered"
        style={{ backgroundColor: "#FEA87D" }}
      >
        <p>
          Legal Disclaimer: Bay Smokes products are not approved by the FDA to
          diagnose, treat, prevent, or cure any illnesses. All products are
          compliant with the US Farm Bill and under 0.3% THC. Bay Smokes
          products and website are intended for ADULT use only. Full disclaimer
          in Terms of Service. Delta8 or other Hemp-Derived THCs will not be
          shipped to states where the product has been expressly banned. Product
          availability varies from state to state per each product’s regulation.
        </p>
        <br />
        <p>
          <strong>© 2024 Bay Smokes, LLC</strong>
        </p>
        <p>62 NE 167th st, Miami, FL 33162</p>
      </section>
    </>
  );
}
