/* eslint-disable react/react-in-jsx-scope */
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { Script, withPrefix } from "gatsby";
import * as Sentry from "@sentry/gatsby";
import fetch from "isomorphic-fetch";

Sentry.init({
  dsn: "https://eb459637a12342169fa678356298ef88@o4505083413397504.ingest.us.sentry.io/4507725192232960",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const client = new ApolloClient({
  link: new HttpLink({
    headers: {
      "X-Shopify-Storefront-Access-Token": "bcbbfafa5f7e90402d742fa04a6d5a3d",
    },
    uri: `https://bay-smokes.myshopify.com/api/2023-10/graphql.json`,
    fetch,
  }),
  cache: new InMemoryCache(),
  ssrMode: true,
});

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);

export const wrapPageElement = ({ element }) => (
  <>
    {element}
    <Script src={withPrefix("scripts/refersion.js")} strategy="idle" />
    {/* <Script src={withPrefix("scripts/retention.js")} /> */}
    <Script
      src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=R8b22B"
      strategy="idle"
    />
    <Script
      src="https://contact.gorgias.help/api/contact-forms/loader.js?v=2"
      strategy="idle"
    />
    {/* <Script src="https://cdn-widgetsrepository.yotpo.com/v1/loader/N7GZ4n72kXdDmKaAuCNxbQ" /> */}
    <Script
      src="https://cdn-widgetsrepository.yotpo.com/v1/loader/_bfaqQGmM8qI_boh1qJLmA"
      strategy="idle"
    />
  </>
);
