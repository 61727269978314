import { useContext, useEffect, useRef } from "react";

import { gql, useMutation } from "@apollo/client";

import useOnClickOutside from "use-onclickoutside";

import useGlobal from "store";

import { Button } from "components/bulma";

import freeProducts from "config/freeProducts";

import { CartContext } from "context/CartContext";

import "./cart.css";

const Cart = ({ isCartOpen, setCartOpen }) => {
  const ref = useRef(null);
  const [globalState] = useGlobal();

  const { data, getCart } = useContext(CartContext);

  const [updateCart] = useMutation(UPDATE_CART);
  const [removeItem] = useMutation(REMOVE_ITEM);

  const closeCart = () => {
    if (isCartOpen) setCartOpen(false);
  };

  useEffect(() => {
    if (globalState.cart.length > 0) {
      setCartOpen(true);
    }
  }, [globalState.cart]);

  const removeCartItem = async ({ item }) => {
    const line = data?.cart?.lines?.edges.find(
      ({ node }) => node.merchandise.id === item.merchandise.id
    )?.node;

    await removeItem({
      variables: {
        cartId: globalState.cartID,
        lineIds: [line.id],
      },
    });

    await getCart();
  };

  const updateQty = async ({ item, inc }) => {
    const line = data?.cart?.lines?.edges.find(
      ({ node }) => node.merchandise.id === item.merchandise.id
    )?.node;

    const lineItem = {
      merchandiseId: item.merchandise.id,
      quantity: inc
        ? freeProducts.includes(item.merchandise.product.title)
          ? 1
          : line.quantity + 1
        : line.quantity - 1,
      sellingPlanId: line.sellingPlanId,
    };

    await updateCart({
      variables: {
        cartId: globalState.cartID,
        lines: [
          {
            ...lineItem,
            id: line.id,
          },
        ],
      },
    });

    await getCart();
  };

  const sendRefersionCheckoutEvent = (cartID) => {
    const rfsn = {
      cart: cartID,
      id: localStorage.getItem("rfsn_v4_id"),
      url: window.location.href,
      aid: localStorage.getItem("rfsn_v4_aid"),
      cs: localStorage.getItem("rfsn_v4_cs"),
    };

    window?.r?.sendCheckoutEvent(
      rfsn.cart,
      rfsn.id,
      rfsn.url,
      rfsn.aid,
      rfsn.cs
    );
  };

  const checkout = () => {
    const url = new URL(data?.cart.checkoutUrl);

    const checkoutToken = url.pathname.toString().split("/").pop();
    sendRefersionCheckoutEvent(checkoutToken);

    window.location.href = data?.cart.checkoutUrl;
  };

  useOnClickOutside(ref, closeCart);

  return (
    <>
      <div
        ref={ref}
        className={isCartOpen ? "side-drawer open box" : "side-drawer box"}
      >
        <div className="is-flex is-flex-direction-column is-justify-content-space-between">
          <div className="mb-3">
            <button
              className="delete is-large is-pulled-right"
              onClick={() => setCartOpen(false)}
            >
              close
            </button>

            <h2 className="title is-3">Your Cart</h2>
          </div>

          <div
            className="is-hidden-tablet"
            style={{
              position: "relative",
              overflowY: "auto",
              overflowX: "clip",
              maxHeight: "50vh",
              overscrollBehavior: "contain",
            }}
          >
            {data?.cart?.lines?.edges?.map(({ node: item }) => (
              <div key={item?.merchandise?.id} className="mt-3">
                <div className="columns is-mobile">
                  <div className="column is-3">
                    <button
                      className="delete is-medium"
                      onClick={() => removeCartItem({ item })}
                      style={{ position: "relative", top: 5 }}
                    />
                    <img
                      src={item?.merchandise?.image?.url}
                      style={{
                        borderColor: "#CCC",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div className="column">
                    <p className="is-size-6 has-text-black has-text-weight-bold">
                      {item?.merchandise?.product?.title}
                    </p>
                    {item.merchandise.selectedOptions.map((option) => (
                      <p key={option.name}>
                        {option.name}: {option.value}
                      </p>
                    ))}
                    <div className={`buttons has-addons are-small mt-2`}>
                      <button
                        className={`button`}
                        onClick={() => updateQty({ item, inc: false })}
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderColor: "#EEE",
                        }}
                      >
                        <span className="has-text-black">-</span>
                      </button>
                      <button
                        className={`button`}
                        style={{
                          borderColor: "#EEE",
                          borderLeftColor: "#FFF",
                          borderRightColor: "#FFF",
                        }}
                      >
                        <span className="has-text-black">{item.quantity}</span>
                      </button>
                      <button
                        className={`button`}
                        onClick={() => updateQty({ item, inc: true })}
                        style={{
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          borderColor: "#EEE",
                        }}
                      >
                        <span className="has-text-black">+</span>
                      </button>
                    </div>
                  </div>
                  <div className="column is-narrow">
                    <p className="subtitle is-6">
                      ${Number(item.merchandise.price.amount).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className="is-hidden-mobile"
            style={{
              position: "relative",
              overflowY: "auto",
              maxHeight: "70vh",
              overscrollBehavior: "contain",
            }}
          >
            {data?.cart?.lines?.edges?.map(({ node: item }) => (
              <div key={item.merchandise.id} className="mt-3 mb-3">
                <div className="columns is-mobile">
                  <div className="column is-3">
                    <button
                      className="delete is-medium"
                      onClick={() => removeCartItem({ item })}
                      style={{ position: "absolute", top: 0 }}
                    />
                    <img
                      src={item.merchandise.image.url}
                      style={{
                        borderColor: "#CCC",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div className="column">
                    <p className="is-size-6 has-text-black has-text-weight-bold">
                      {item.merchandise.product.title}
                    </p>
                    {item.merchandise.selectedOptions.map((option) => (
                      <p key={option.name}>
                        {option.name}: {option.value}
                      </p>
                    ))}
                    <div className={`buttons has-addons are-small mt-2`}>
                      <button
                        className={`button`}
                        onClick={() => updateQty({ item, inc: false })}
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderColor: "#EEE",
                        }}
                      >
                        <span className="has-text-black">-</span>
                      </button>
                      <button
                        className={`button`}
                        style={{
                          borderColor: "#EEE",
                          borderLeftColor: "#FFF",
                          borderRightColor: "#FFF",
                        }}
                      >
                        <span className="has-text-black">{item.quantity}</span>
                      </button>
                      <button
                        className={`button`}
                        onClick={() => updateQty({ item, inc: true })}
                        style={{
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          borderColor: "#EEE",
                        }}
                      >
                        <span className="has-text-black">+</span>
                      </button>
                    </div>
                  </div>
                  <div className="column is-narrow">
                    <p className="subtitle is-6">
                      ${item.merchandise.price.amount}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {data?.cart?.lines?.edges?.length > 0 ? (
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                width: "90%",
                backgroundColor: "white",
                padding: "20px",
                border: "1px solid #CCC",
                borderRadius: "5px",
              }}
            >
              <div className="level is-mobile">
                <div className="level-left">
                  <p className="title is-3">Total</p>
                </div>
                <div className="level-right">
                  <p className="title is-3">
                    $
                    {Number(
                      data?.cart?.estimatedCost?.subtotalAmount.amount
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
              <Button
                disabled={!data?.cart.checkoutUrl}
                color="primary"
                block
                size="medium"
                icon="check-circle"
                action={() => checkout()}
              >
                Checkout
              </Button>
            </div>
          ) : (
            <div
              className="mt-6"
              style={{
                position: "absolute",
                bottom: "20px",
                width: "90%",
                backgroundColor: "white",
                padding: "20px",
                border: "1px solid #CCC",
                borderRadius: "5px",
              }}
            >
              <p className="title is-3">Your cart is empty</p>
              <p className="subtitle">Fill in your cart with amazing items</p>
              <Button action={() => setCartOpen(false)}>Shop now</Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;

// const CART = gql`
//   query Cart($id: ID!) {
//     cart(id: $id) {
//       id
//       checkoutUrl
//       createdAt
//       updatedAt
//       lines(first: 100) {
//         edges {
//           node {
//             id
//             quantity
//             merchandise {
//               ... on ProductVariant {
//                 id
//                 title
//                 selectedOptions {
//                   name
//                   value
//                 }
//                 image {
//                   id
//                   url
//                 }
//                 price {
//                   amount
//                   currencyCode
//                 }
//                 product {
//                   id
//                   title
//                 }
//               }
//             }
//           }
//         }
//       }
//       attributes {
//         key
//         value
//       }
//       estimatedCost {
//         totalAmount {
//           amount
//           currencyCode
//         }
//         subtotalAmount {
//           amount
//           currencyCode
//         }
//         totalTaxAmount {
//           amount
//           currencyCode
//         }
//         totalDutyAmount {
//           amount
//           currencyCode
//         }
//       }
//     }
//   }
// `;

const REMOVE_ITEM = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

const UPDATE_CART = gql`
  mutation UpdateCart($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                }
              }
              quantity
            }
          }
        }
        attributes {
          key
          value
        }
        estimatedCost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

// const ADD_TO_CART = gql`
//   mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
//     cartLinesAdd(cartId: $cartId, lines: $lines) {
//       cart {
//         id
//         createdAt
//         updatedAt
//         lines(first: 10) {
//           edges {
//             node {
//               id
//               merchandise {
//                 ... on ProductVariant {
//                   id
//                 }
//               }
//             }
//           }
//         }
//         attributes {
//           key
//           value
//         }
//         estimatedCost {
//           totalAmount {
//             amount
//             currencyCode
//           }
//           subtotalAmount {
//             amount
//             currencyCode
//           }
//           totalTaxAmount {
//             amount
//             currencyCode
//           }
//           totalDutyAmount {
//             amount
//             currencyCode
//           }
//         }
//       }
//     }
//   }
// `;
