import globalHook from "use-global-hook";

import * as actions from "./actions";

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_cart_id`;
const accessTokenStorageKey = "access_token";

const existingCartID = isBrowser ? localStorage.getItem(localStorageKey) : null;
const accessToken = isBrowser
  ? localStorage.getItem(accessTokenStorageKey)
  : null;

const initialState = {
  art: null,
  cart: [],
  accessToken: accessToken,
  cartID: existingCartID,
};

const useGlobal = globalHook(initialState, actions);

export default useGlobal;
